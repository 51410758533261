<template>
  <div id="media-rSidebar">
    <div class="info" v-if="mediaInfo.check" draggable="false">
      <div class="attachment-details" v-if="mediaInfo.status !== 'error'">
        <h2 v-text="mediaInfo.type === 'image' ? '图片详情' : '视频详情'"></h2>
        <div class="attachment-info">
          <div class="thumbnail-image">
            <img :src="mediaInfo.thum" alt="">
          </div>
          <div class="details">
            <div class="filename">{{mediaInfo.name}}</div>
            <div class="createTime">{{mediaInfo.create_time_format}}</div>
            <div class="fileSize" v-html="mediaInfoSize(mediaInfo.size)"></div>
            <div class="dimensions">{{mediaInfo.width}} X {{mediaInfo.height}}</div>
          </div>
        </div>
        <div class="attachment-form">
          <el-form :label-position="labelPosition" label-width="60px">
            <el-form-item label="URL">
              <el-input v-model="mediaInfo.url" readonly>
                <el-button slot="append" @click="handleTarget(mediaInfo.url)">预览</el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="名称">
              <el-input v-model="mediaInfo.key" readonly></el-input>
            </el-form-item>
            <el-form-item label="作者">
              <el-input v-model="mediaInfo.author" readonly></el-input>
            </el-form-item>
            <el-form-item label="创建时间">
              <el-input v-model="mediaInfo.create_time" readonly></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'media-rSidebar',
  props: {
    mediaInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      labelPosition: 'right'
    }
  },
  created () {
  },
  methods: {
    mediaInfoSize (data) {
      let fileSize = (data / 1024 / 1024) * 100
      if (fileSize < 1) {
        fileSize = Math.floor((data / 1024) * 100) / 100 + 'KB'
      } else {
        fileSize = Math.floor(fileSize) / 100 + 'MB'
      }
      return fileSize
    },
    handleTarget (data) {
      window.open(data)
    }
  }
}
</script>
<style lang="scss">
  #media-rSidebar {
    .el-input__inner {
      height: 30px;
      line-height: 30px;
    }
    .el-form-item__label {
      font-size: 12px;
    }
  }
</style>
<style lang="scss" scoped>
 #media-rSidebar {
   width: 300px;
   height: 100%;
   padding: 0 20px;
   box-sizing: border-box;
   float: right;
   background-color: #f9f9f9;
   .attachment-details {
     h2 {
       position: relative;
       font-weight: 600;
       text-transform: uppercase;
       font-size: 12px;
       color: #666;
       margin: 24px 0 8px;
       text-align: left;
     }
     .attachment-info {
       overflow: hidden;
       min-height: 80px;
       margin-bottom: 16px;
       line-height: 18px;
       color: #666;
       border-bottom: 1px solid #ddd;
       padding-bottom: 12px;
       .thumbnail-image {
         position: relative;
         float: left;
         max-width: 120px;
         max-height: 120px;
         margin-top: 5px;
         margin-right: 40px;
         margin-bottom: 5px;
         img {
           display: block;
           max-width: 120px;
           max-height: 120px;
         }
         &:after {
           content: '';
           display: block;
           position: absolute;
           top: 0;
           left: 0;
           right: 0;
           bottom: 0;
           box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
           overflow: hidden;
         }
       }
       .details {
         float: left;
         font-size: 12px;
         max-width: 100%;
         text-align: left;
         .filename {
           font-weight: 600;
           color: #444;
           word-wrap: break-word;
         }
       }
       &:after {
         content: '';
         display: block;
         height: 0;
         visibility: hidden;
         clear: both;
       }
     }
   }
 }
</style>
